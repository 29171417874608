<script setup>

</script>

<template>
  <v-app>
    <v-layout>
      <v-main>
        <slot />
      </v-main>
    </v-layout>

    <ConfirmDialog />
  </v-app>
</template>

<style lang="scss" scoped>

</style>
